.title-border {
	font: 400 30px/38px $main-font;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 0;

	&__top {
		display: block;
		height: 1px;
		background: $white;
		width: 80px;
		margin: 0 auto 10px;
	}

	&__bottom {
		display: block;
		height: 1px;
		background: $white;
		width: 80px;
		margin: 10px auto 0;
	}
}

h2 {
	text-transform: uppercase;
	font: 400 24px/30px $main-font;

	@include min-screen(768px) {
		font: 400 35px/40px $main-font;
	}
}