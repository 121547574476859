.footer {
	clear: both;
	padding: 80px 0 40px;

	&__social {

		width: 90px;
		margin: 0 auto;

		.fa {
			font-size: 30px;
			margin: 0 10px;
		}

		a {
			float: left;
			margin-bottom: 30px;
		}
	}

	&__menu {
		margin: 20px auto;
		clear: both;
		text-align: center;
		overflow: hidden;

		@include min-screen(768px) {
			width: 569px;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				display: block;
				float: left;

				a {
					display: block;
					margin: 0 8px;
					text-transform: uppercase;
					font: 400 18px/24px $main-font;
					color: $white;
					letter-spacing: $spacing;
					transition: $transition;

					&:hover {
						color: $blue;
					}
				}
			}
		}
	}

	&__copyright {
		width: 100%;
		clear: both;
		text-align: center;
	}
}