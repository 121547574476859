.form {
	margin-top: 30px;
	
	@include min-screen(1024px) {
		width: 80%;
		margin: 0 auto;
	}

	&__field {

		width: 100%;

		@include min-screen(768px) {
			width: 50%;
			float: left;
		}

		input {
			padding: 10px;
			border: 0;
			width: 100%;
			color: $dark-brown;
			outline: none;
			background: rgba(255,255,255, 0.8);
			border-radius: 0;

			@include min-screen(768px) {
				width: 95%;
			}

			&:focus {
				background: rgba(255,255,255, 1);
			}
		}
	}

	.wpcf7-text, .wpcf7-textarea {
		padding: 10px;
		border: 0;
		width: 100%;
		color: $dark-brown;
		outline: none;
		background: rgba(255,255,255, 0.8);
		margin-bottom: -8px;
		border-radius: 0;
		border-right: 11px solid $black;

		&:focus {
			background: rgba(255,255,255, 1);
		}
	}

	.wpcf7-textarea {
		margin-bottom: 0;
		border-radius: 0;
	}

	&__textarea {
		padding: 20px 0;
		overflow: hidden;
		clear: both;
		border-radius: 0;

		textarea {
			padding: 10px;
			border: 0;
			outline: none;
			background: rgba(255,255,255, 0.8);
			color: $dark-brown;
			width: 100%;

			@include min-screen(768px) {
				width: 97.5%;
			}

			&:focus {
				background: rgba(255,255,255, 1);

			}
		}
	}

	&__submit {
		background: $blue;
		padding: 10px 20px;
		border: 0;
		color: $white;
		text-transform: uppercase;
		margin: 0 auto;
		display: block;
		transition: $transition;
		font: 400 20px/30px $main-font;
		outline: none;
		border-radius: 0;

		@include min-screen(768px) {
			font: 400 24px/30px $main-font;
		}

		&:hover, &:focus, &:active {
			color: $blue;
			background: $white;
		}
	}
}

.contact {

	&__submit {
		background: $blue;
		padding: 10px 20px;
		border: 0;
		color: $white;
		text-transform: uppercase;
		display: block;
		transition: $transition;
		font: 400 24px/30px $main-font;
		outline: none;
		border-radius: 0;

		&:hover, &:focus, &:active {
			color: $blue;
			background: $white;
		}
	}
}

.wpcf7-validation-errors, .wpcf7-mail-sent-ok {
	border: 2px solid $blue!important;
	padding: 10px!important;
	text-align: center!important;
	width: 80%!important;
	margin: 0 auto!important;
}

.wpcf7-not-valid-tip {
	color: $blue!important;
	margin-top: 5px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $dark-brown;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $dark-brown;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $dark-brown;
}
:-moz-placeholder { /* Firefox 18- */
  color: $dark-brown;
}