.about {

	&__person {
		overflow: hidden;
		margin-bottom: 50px;
		clear: both;
	}

	&__image {
		float: left;
		margin-bottom: 30px;

		@include min-screen(768px) {
			width: 35%;
		}

		img {
			width: 100%;
		}
	}

	&__profile {
		float: right;

		@include min-screen(768px) {
			width: 60%;
		}

		&__title {
			text-transform: uppercase;
			font: 400 30px/30px $main-font;
		}

		&__text {
			font: 400 16px/24px $main-font;

			@include min-screen(768px) {
				font: 400 20px/27px $main-font;
			}
		}
	}

	&__history {

		&__left {

			@include min-screen(768px) {
				width: 35%;
				float: left;
			}
		}

		&__right {

			@include min-screen(768px) {
				width: 60%;
				float: right;
			}

			p {
				font: 400 20px/27px $main-font;
			}
		}
	}
}