.home {

	&__banner {
		width: 100%;
		margin-bottom: -3px;
	}

	&__block {
		width: 50%;
		float: left;
		display: block;
		position: relative;
		border-right: 1px solid $dark-brown;
		border-left: 1px solid $dark-brown;
		padding: 6% 0;
		color: $white;
		box-sizing: border-box;
		height: 200px;

		@include min-screen(768px) {
			width: 25%;
		}

		h2 {
			text-align: center;
			font: 400 25px/25px $main-font;
			text-transform: uppercase;
			letter-spacing: 2px;
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    -webkit-transform: translate(-50%, -50%);
		    -ms-transform: translate(-50%, -50%);
		    transform: translate(-50%, -50%);

			@include min-screen(768px) {
				font: 400 2.5vw/40px $main-font;
			}
		}

		&__background {
			background-position: center center;
		    background-size: 100% auto;
		    height: 100%;
		    left: 0;
		    position: absolute;
		    top: 0;
		    width: 100%;
		    z-index: -1;
		    opacity: 0.5;
		    filter: grayscale(100%);
		    backface-visibility: hidden;
		    transition: $transition;
		}

		/*
		&__background {
			position: absolute;
			width: 100%;
			top: 0;
			z-index: -1;
			opacity: 0.5;
			transition: $transition;
			filter: grayscale(100%);
			backface-visibility: hidden;
		}
		*/

		&:hover .home__block__background {
			opacity: 1;
			transition: $transition;
			filter: grayscale(0%);
		}

		&:hover {
			color: $white;
		}
	}
}