html {
    font-size: ($base-font-size / 16px) * 1em; /* [1] */
    line-height: $base-line-height / $base-font-size; /* [1] */
    padding: 0;
    margin: 0;
    text-rendering: auto;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $main-font;
    font-size: 18px;
    color: $white;
    background: $dark-brown;
}

body {
    margin: 0;
    padding: 0;
}

.hidden {
    position: absolute;
    left: -9999px;
}

p {
    margin-bottom: 15px;

}

.clear {
    clear: both;
    display: block;
}

.black-background {
    background: $black;
}

.padding {
    padding: 60px 0;
}