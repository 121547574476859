@media (max-width: 988px){
  .wp-caption {
    /* Force the box to be 100% */
    width: 100% !important;
  }
  #content .wp-caption a img {
    /* Scale down if too big */
    max-width: 99.03225806%; /* 614/620 */
    height: auto;
  }
}