.contact {

	&__left {
		width: 100%;
		float: left;

		@include min-screen(768px) {
			width: 300px;
		}

		@include min-screen(1024px) {
			width: 350px;
		}

		p {
			font: 400 16px/24px $main-font;

			@include min-screen(768px) {
				font: 400 20px/24px $main-font;
			}
		}
	}

	&__right {
		width: 100%;
		float: right;

		@include min-screen(768px) {
			width: 320px;
		}

		@include min-screen(1024px) {
			width: 550px;
		}
	}
}