a {
	text-decoration: none;
	transition: $transition;
	color: $blue;

	&:hover, &:focus, &:active {
		transition: $transition;
		text-decoration: none;
		color: $white;
	}
}