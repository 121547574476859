.gallery {
	list-style-type: none;
	margin: 0;
	padding: 0;

	&__item {

		@include min-screen(768px) {
			width: 216px;
			float: left;

		}

		@include min-screen(1024px) {
			width: 218px;
		}

		@include min-screen(1280px) {
			width: 243px;
		}

		img {
			width: 100%;
			
			opacity: 0.8;
			transition: $transition;
			backface-visibility: hidden;
			filter: grayscale(100%);

			@include min-screen(768px) {
				height: 140px;
				margin-bottom: -4px;

			}

			@include min-screen(1024px) {
				height: 140px;
			}

			@include min-screen(1280px) {
				height: 150px;
			}

			&:hover {
				opacity: 1;
				filter: grayscale(0%);
			}
		}
	}
}