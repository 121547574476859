.listen {
	position: relative;
	background: $black;

	@include min-screen(1024px) {
		height: 600px;
	}

	&:nth-child(2n) {
		background: $dark-brown;
	}

	&__date {
		font: 400 17px/15px $main-font;
	}

	&__image {
		position: relative;
		margin-bottom: 30px;

		@include min-screen(768px) {
			float: left;
			width: 250px;
		}

		@include min-screen(1024px) {
			float: left;
			width: 350px;
		}

		img {
			width: 100%;
		}
	}

	&__songs {

		@include min-screen(768px) {
			float: right;
			width: 350px;
		}

		@include min-screen(1024px) {
			float: right;
			width: 490px;
		}

		@include min-screen(1280px) {
			width: 590px;
		}

		ul {
            
            list-style-type: none;
            margin: 0;
            padding: 0;

            @include min-screen(768px) {
            	column-count: 2;
            }

            li {
            	margin-bottom: 5px;
            	font: 400 16px/24px $main-font;
            	display: block;
            	position: relative;
            	padding-left: 25px;

            	@include min-screen(768px) {
            		font: 400 20px/24px $main-font;
            	}

            	a {
            		color: $blue;

            		&:hover, &:focus, &:active {
            			color: $white;
            		}

            		&:after {
            			content: "\f144";
            			font-family: FontAwesome;
            			position: absolute;
            			left: 0;
            			font-size: 15px;
            			top: 2px;
            		}
            	}
            }
		}
	}

	&__button {
		margin: 20px 0 0;
		width: 100%;
		background: $blue;
		display: block;
		padding: 10px;
		font: 700 20px/20px $main-font;
		text-transform: uppercase;
		text-align: center;
		color: $white;

		.fa {
			font-size: 15px;
		}

		&:hover {
			color: $blue;
			background: $white;
		}
	}
}