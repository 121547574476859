.blue-button {
	background: $blue;
	padding: 10px 20px;
	font: 400 20px/30px $main-font;
	text-transform: uppercase;
	display: inline-block;
	color: $white;
	margin-top: 20px;

	@include min-screen(1024px) {
		font: 400 24px/30px $main-font;
	}

	&:hover, &:focus, &:active {
		background: $white;
		color: $blue;
	}
}