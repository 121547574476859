.header {
	background: $dark-brown;
	padding: 20px 0;
	height: 170px;
	position: relative;

	@include min-screen(768px) {
		padding: 40px 0;
		height: 250px;
	}

	&__logo {
		background: url('../img/logo.svg') no-repeat;
		height: 150px;
		width: 200px;
		position: relative;

		@include min-screen(768px) {
			height: 150px;
			width: 250px;
			margin: 0 auto;
		}

		&__link {
			position: absolute;
			height: 150px;
			width: 250px;
		}
	}

	&__menu {	
		position: relative;

		@include min-screen(768px) {
			width: 629px;
		    overflow: hidden;
		    margin: 0 auto;
		}

		nav {

			@include max-screen(768px) {
				margin: 0;
			    width: 100%;
			    position: fixed;
			    top: 0;
			    left: 0;
			    right: 0;
			    bottom: 0;
			    padding: 40px;
			    z-index: 9999;
			    min-height: 100%;
			    height: 100%;
			    background: $dark-brown;
			    display: none;
			}

			@include min-screen(768px) {
				display: block;
			}
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				
				@include min-screen(768px) {
					display: block;
					float: left;
				}

				a {
					text-transform: uppercase;
					font: 400 22px/24px $main-font;
					color: $white;
					letter-spacing: $spacing;
					transition: $transition;
					padding: 20px 0;
					display: block;
					
					@include min-screen(768px) {
						float: left;
						padding: 0 13px;
						font: 400 18px/24px $main-font;
					}

					&:hover, &:focus, &:active {
						color: $blue;
					}
				}

				&.current-menu-item a {
					color: $blue;
				}

			}
		}

		&__open {
			display: block;
			width: 30px;
			height: 50px;
			position: absolute;
			color: $white;
			top: 10px;
			right: 0;

			.fa {
				color: $white;
				font-size: 35px;
			}

			@include min-screen(768px) {
				display: none;
			}

		}

		&__close {
			position: absolute;
			right: 20px;
			top: 30px;
			font-size: 30px;

			@include min-screen(768px) {
				display: none;
			}
		}
	}
}